import Countdown from '../../shared/count-down';
import nftart from '../../../images/ezgif.com-gif-maker.gif';
import cigargif from '../../../images/GifHavanaArnold.gif';
import team1 from '../../../images/1feee1ca-1f81-4bd1-8950-aefb0644717d.jpeg';
import team2 from '../../../images/38df8acb-6ebb-47f4-a147-de41f92193d2.jpeg';
import team3 from '../../../images/56a6d707-513c-4415-8503-6b6f9a0e6c4d.jpeg';
import team4 from '../../../images/856b602b-9cee-4cfa-adc9-1d38ac70876d.jpeg';

import cpng1 from '../../../images/output-onlinepngtools (2).png';
import cigargifbg from '../../../images/108fdcd1-6b08-465b-8fbc-07ddc2cbe03c.jpeg';
import king1 from '../../../images/king1.png';
import king2 from '../../../images/king2.png';
import king3 from '../../../images/king3.png';
import king4 from '../../../images/king4.png';
import { BsArrowUp, BsArrowRight } from 'react-icons/bs';
import cigarvideothumb from '../../../images/2c0d4a3a-134c-4441-8b5f-129dda90fb32.png';
import vidthumb from '../../../images/vidthumb.png';

import React, { ChangeEvent, Fragment, useEffect, useRef, useState, refs } from 'react';
import { Chart, LinearScale, PointElement, LineElement, Legend, LineController, CategoryScale } from 'chart.js';
import { BsFillPlayBtnFill } from 'react-icons/bs';
import { SiYoutube } from 'react-icons/si';


const data = {
  labels: ['', '', '', ''],
  datasets: [
    {
      label: 'Rainfall',
      fill: false,
      lineTension: 0.3,
      backgroundColor: '#330c18',
      borderColor: '#fff',
      borderWidth: 2,
      data: [0, 20, 50, 100]
    }
  ]
}

class HomePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showVid: false
    }
  }

  showVideo = () => {
    if (!this.state.showVid)
      this.setState({ showVid: true });
  }

  componentDidMount() {

    setTimeout(function () {
      var ctx = document.getElementById('chartRef');
      Chart.register(LineController, LinearScale, PointElement, LineElement, Legend, CategoryScale);

      var myChart = new Chart(ctx, {
        type: 'line',
        data: data,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: 'Chart.js Scatter Chart'
            }
          },
          scales: {
            x: {
              display: false,
              // categoryPercentage: 1.0,
              // barPercentage: 1.0,
              // ticks: {
              //   beginAtZero: true,
              //   display: false,                
              // },
              // gridLines: {
              //   drawTicks: false,
              //   zeroLineColor: '#ffcc33'         
              // },
            },
            y: {
              display: false
            },

          }
        },
      });
    }, 300);
  }

  render() {
    return (
      <div className="page-wrapper">
        <div className="section-1-wrapper">
          <div className="row section">
            <div className="col-md-6 col-sm-12 site-logo">
              <h1>SOLANA HAVANA CIGAR CLUB</h1>
              <p className="sub-title">
                500 deflationary, algorithmically generated cigars
              </p>
              <p className="site-desc">
                Welcome to the most exclusive cigar club in the
                metaverse.
                Don’t worry, you came just in time, grab
                yourself a unique cigar NFT, kick back and watch the show.
              </p>
            </div>

            {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#myModal">
  Launch demo modal
</button> */}
            <div className="col-md-6 col-sm-12 fade-in vid-wrapper" data-bs-toggle="modal" data-bs-target="#myModal" onClick={this.showVideo}>
              <SiYoutube className="play-btn"></SiYoutube>
              <img src={vidthumb} alt="havana-nft" className="img-fluid cigar" />
            </div>
          </div>
          {/* <div className="row section">
            <div className="col-12">
              <Countdown date={1635364800000} />
              <button className="mint-btn">                
                <a href="https://mint.solanahavanaclub.com/" target="_blank">MINT IS OPEN! CLICK HERE</a>
              </button>
            </div>
          </div> */}
          <div className="row align-center section">
            <div className="col-12">
              <button className="mint-btn">
                <a href="https://discord.gg/uPFmPXvXnQ" target="_blank">JOIN DISCORD</a>
              </button>
            </div>
            <div className="col-12">
              <button className="mint-btn">
                <a href="https://magiceden.io/marketplace/solana_havana_cigar_club" target="_blank">Magic Eden</a>
              </button>
              <button className="mint-btn">
                <a href="https://rarity.solanahavanaclub.com/" target="_blank">Rarity Table</a>
              </button>
            </div>
          </div>
        </div>
        <div className="jump-element-wrapper">
          <img src={cpng1} className="j-e-1" />
          <img src={cpng1} className="j-e-2" />
          <img src={cpng1} className="j-e-3" />
          <img src={cpng1} className="j-e-4" />
          <img src={cpng1} className="j-e-5" />
          <img src={cpng1} className="j-e-6" />
          <img src={cpng1} className="j-e-7" />
          <img src={cpng1} className="j-e-8" />
          <img src={cpng1} className="j-e-9" />
          <img src={cpng1} className="j-e-10" />
          <img src={cpng1} className="j-e-11" />
          <img src={cpng1} className="j-e-12" />
          <img src={cpng1} className="j-e-13" />
          <img src={cpng1} className="j-e-14" />
          <img src={cpng1} className="j-e-15" />
          <img src={cpng1} className="j-e-16" />
          <img src={cpng1} className="j-e-17" />
          <img src={cpng1} className="j-e-18" />
          <img src={cpng1} className="j-e-19" />
          <img src={cpng1} className="j-e-20" />
        </div>
        <div className="section-2">
          <div className="row section royalties-desc">
            <div className="col-md-12">
              <h1>THE HEAVY SMOKER FUND</h1>
              <p>
                80 percent of all royalties will go to the fund, that once a week will send the heavy smoker from marketplace to marketplace to smoke the cheapest cigars, as much as he can until the fund runs dry
                Thus decreasing cigar supply every week, and raising the floor price for the gentleman that knows how to hodl their.
              </p>

            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 chart-wrapper">
              <div className="y-axis-label">
                <h3>
                  Floor Price
                  <BsArrowUp className="arrow-up"></BsArrowUp>
                </h3>
              </div>
              <div className="x-axis-label">
                <h3>
                  Time
                  <BsArrowRight></BsArrowRight>
                </h3>
              </div>
              <canvas id="chartRef" ></canvas>
            </div>
            <div className="col-md-6 col-sm-12">
              <img src={cigargif} alt="cigar-gif" className="img-fluid cigar-gif" />
            </div>
          </div>
        </div>
        <div className="jump-element-wrapper">
          <img src={cpng1} className="j-e-1" />
          <img src={cpng1} className="j-e-2" />
          <img src={cpng1} className="j-e-3" />
          <img src={cpng1} className="j-e-4" />
          <img src={cpng1} className="j-e-5" />
          <img src={cpng1} className="j-e-6" />
          <img src={cpng1} className="j-e-7" />
          <img src={cpng1} className="j-e-8" />
          <img src={cpng1} className="j-e-9" />
          <img src={cpng1} className="j-e-10" />
          <img src={cpng1} className="j-e-11" />
          <img src={cpng1} className="j-e-12" />
          <img src={cpng1} className="j-e-13" />
          <img src={cpng1} className="j-e-14" />
          <img src={cpng1} className="j-e-15" />
          <img src={cpng1} className="j-e-16" />
          <img src={cpng1} className="j-e-17" />
          <img src={cpng1} className="j-e-18" />
          <img src={cpng1} className="j-e-19" />
          <img src={cpng1} className="j-e-20" />
        </div>
        <div className="section-6">
          <div className="row section royalties-desc">
            <div className="col-md-12">
              <h1>WISH FOR THE CROWN!</h1>
              <p>
                4 out of 500 cigars will be royals.
                One king = 10 sol that will be airdropped to your wallet immediately, once we sold out.
                the more you mint the bigger your odds are.
                After all, life is a game of luck.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-12 king-pic-wrapper">
              <img src={king1} className="img-fluid bg-shadow-black pixel-border" />
            </div>
            <div className="col-md-3 col-sm-12 king-pic-wrapper">
              <img src={king2} className="img-fluid bg-shadow-black pixel-border" />
            </div>
            <div className="col-md-3 col-sm-12 king-pic-wrapper">
              <img src={king3} className="img-fluid bg-shadow-black pixel-border" />
            </div>
            <div className="col-md-3 col-sm-12 king-pic-wrapper">
              <img src={king4} className="img-fluid bg-shadow-black pixel-border" />
            </div>
          </div>
        </div>
        <div className="jump-element-wrapper">
          <img src={cpng1} className="j-e-1" />
          <img src={cpng1} className="j-e-2" />
          <img src={cpng1} className="j-e-3" />
          <img src={cpng1} className="j-e-4" />
          <img src={cpng1} className="j-e-5" />
          <img src={cpng1} className="j-e-6" />
          <img src={cpng1} className="j-e-7" />
          <img src={cpng1} className="j-e-8" />
          <img src={cpng1} className="j-e-9" />
          <img src={cpng1} className="j-e-10" />
          <img src={cpng1} className="j-e-11" />
          <img src={cpng1} className="j-e-12" />
          <img src={cpng1} className="j-e-13" />
          <img src={cpng1} className="j-e-14" />
          <img src={cpng1} className="j-e-15" />
          <img src={cpng1} className="j-e-16" />
          <img src={cpng1} className="j-e-17" />
          <img src={cpng1} className="j-e-18" />
          <img src={cpng1} className="j-e-19" />
          <img src={cpng1} className="j-e-20" />
        </div>
        <div className="section-3">
          <div className="row section">
            <div className="col-12">
              <h1>FUTURE PLANS</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="timeline">
                <div className="tl-container tl-left">
                  <div className="tl-content pixel-border">
                    <h2>October 2021</h2>
                    <p><ul>
                      <li><del>Create the cigars</del></li>
                      <li><del>Set up discord + twitter</del></li>
                      <li><del>Upload awesome website</del></li>
                      <li><del>Run a discord + twitter giveaways campaign</del></li>
                      <li><del>show up on <a href="https://howrare.is/">howrare.is</a> upcoming lunches</del></li>
                      <li><del>Find marketplace to launch on (Magic Eden)</del></li>
                      <li><del>Collaborate with other projects at pre-mint stage</del></li>
                      <li><del>Collaborate with influencers for hosted giveaways and shill</del></li>
                    </ul>
                    </p>
                  </div>
                </div>
                <div className="tl-container tl-right">
                  <div className="tl-content pixel-border">
                    <h2>October 2021 Phase #2</h2>
                    <p>
                      <ul>
                        <li><del>Stress-test mint process</del></li>
                        <li><del>Add cigars to candy machine</del></li>
                        <li><del>Mint successfully</del></li>
                        <li><del>Publish rarity table</del></li>
                        <li><del>Get verified on marketplace - Magic Eden!</del></li>
                        <li>Start the heavy-smoker fund</li>
                        <li>Integrate with rarity sniper and howrare.is</li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div className="tl-container tl-left">
                  <div className="tl-content pixel-border">
                    <h2>December 2021</h2>
                    <p>
                      <ul>
                        <li>Build the cigar ashtrays channel on discord</li>
                        <li>Build the future accessory ashtrays NFTs to go along with the cigar - Airdrop for cigar holders!</li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div className="tl-container tl-right">
                  <div className="tl-content pixel-border">
                    <h2>January 2022</h2>
                    <p>Launching the 2nd phase of the Solana Havana Club, You will want to stay around for that!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="jump-element-wrapper">
          <img src={cpng1} className="j-e-1" />
          <img src={cpng1} className="j-e-2" />
          <img src={cpng1} className="j-e-3" />
          <img src={cpng1} className="j-e-4" />
          <img src={cpng1} className="j-e-5" />
          <img src={cpng1} className="j-e-6" />
          <img src={cpng1} className="j-e-7" />
          <img src={cpng1} className="j-e-8" />
          <img src={cpng1} className="j-e-9" />
          <img src={cpng1} className="j-e-10" />
          <img src={cpng1} className="j-e-11" />
          <img src={cpng1} className="j-e-12" />
          <img src={cpng1} className="j-e-13" />
          <img src={cpng1} className="j-e-14" />
          <img src={cpng1} className="j-e-15" />
          <img src={cpng1} className="j-e-16" />
          <img src={cpng1} className="j-e-17" />
          <img src={cpng1} className="j-e-18" />
          <img src={cpng1} className="j-e-19" />
          <img src={cpng1} className="j-e-20" />
        </div>
        <div className="section-4">
          <div className="row section">
            <div className="col-12">
              <h1>MEET THE TEAM</h1>
              <div className="row">
                <div className="col-md-3 col-sm-12 tm-tile">
                  <img src={team2} className="img-fluid team-img" />
                  <h4>0xChe</h4>
                  <span>
                    <strong>The Developer</strong> - 15 years of programming in every possible field, loves yodelling, hodling, and sweeping the floor.
                  </span>
                </div>
                <div className="col-md-3 col-sm-12 tm-tile">
                  <img src={team3} className="img-fluid team-img" />
                  <h4>0xFidel</h4>
                  <span>
                    <strong>Project Manager</strong> - 30 years of being awesome at everything. with an MBA and a lot of experience in managing fintech companies. Now He’s here to make this project profitable AF.
                  </span>
                </div>
                <div className="col-md-3 col-sm-12 tm-tile">
                  <img src={team1} className="img-fluid team-img" />
                  <h4>0xGrouch</h4>
                  <span>
                    <strong>Community Manager</strong> - He’s the one creating
                    order and clearing all the smoke around here.
                  </span>
                </div>
                <div className="col-md-3 col-sm-12 tm-tile">
                  <img src={team4} className="img-fluid team-img" />
                  <h4>0xSanchez</h4>
                  <span>
                    <strong>Artist</strong>  - Our skilled artist developed truly magnificent skills growing up as a street painter in Cuba. where he got inspired by the cigar smoking clubs.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="jump-element-wrapper">
          <img src={cpng1} className="j-e-1" />
          <img src={cpng1} className="j-e-2" />
          <img src={cpng1} className="j-e-3" />
          <img src={cpng1} className="j-e-4" />
          <img src={cpng1} className="j-e-5" />
          <img src={cpng1} className="j-e-6" />
          <img src={cpng1} className="j-e-7" />
          <img src={cpng1} className="j-e-8" />
          <img src={cpng1} className="j-e-9" />
          <img src={cpng1} className="j-e-10" />
          <img src={cpng1} className="j-e-11" />
          <img src={cpng1} className="j-e-12" />
          <img src={cpng1} className="j-e-13" />
          <img src={cpng1} className="j-e-14" />
          <img src={cpng1} className="j-e-15" />
          <img src={cpng1} className="j-e-16" />
          <img src={cpng1} className="j-e-17" />
          <img src={cpng1} className="j-e-18" />
          <img src={cpng1} className="j-e-19" />
          <img src={cpng1} className="j-e-20" />
        </div>
        <div className="section-5">
          <div className="row section">
            <div className="col-12">
              <h1>FAQ</h1>
              <div className="row">
                <div className="col-12">
                  <h2 className="question">What is the minting date?</h2>
                  <p className="answer">October 27th, 8PM UTC</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h2 className="question">How do I purchase a cigar?</h2>
                  <p className="answer">You can mint a cigar directly on our site! At the top of this page click "Connect Wallet" and then there will be a button to purchase your cigar once the sale is live!</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h2 className="question">What will be the mint price?</h2>
                  <p className="answer">0.25 Solana per cigar</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h2 className="question">Which wallet should I use?</h2>
                  <p className="answer">Phantom, Solflare and Sollet all work well. If you own a different wallet, feel free to reach out on Twitter or Discord to check if it's compatible</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h2 className="question">Will the cigars be listed on secondary-market?</h2>
                  <p className="answer">Yes, Magic Eden!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal */}
        <div className="modal fade" id="myModal" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">


              <div class="modal-body">

                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                <div class="ratio ratio-16x9">
                  {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/J2mNJi-_G68?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                  {this.state.showVid ?
                    <iframe width="560" height="315" className="cigar-video" src="https://www.youtube.com/embed/J2mNJi-_G68?mute=0&enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    : ""
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* <div class="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">


              <div class="modal-body">

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="embed-responsive embed-responsive-16by9">
                  <iframe width="560" height="315" className="cigar-video " allow="autoplay" src="https://www.youtube.com/embed/J2mNJi-_G68" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                </div>
              </div>

            </div>
          </div>
        </div> */}
      </div>
    )
  }
}

export default HomePage;

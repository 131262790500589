import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';

import HomePage from './components/root/home/home';
import React, { ChangeEvent, Fragment, useEffect, useRef, useState, refs } from 'react';
import { SiDiscord, SiTwitter, SiTiktok, SiInstagram } from 'react-icons/si';

class App extends React.Component {

  componentDidMount() {
    let smokeDensity = 50;
    const imageUrl =
      "http://www.blog.jonnycornwell.com/wp-content/uploads/2012/07/Smoke10.png";
    const playground = document.getElementById("playground");
    let smokeGeneratorTimer;
    let smokeObject;
    let smokes = [];
    const smokeLife = 6000;

    function loadImage() {
      return new Promise((resolve, reject) => {
        let Img = new Image();
        Img.src = imageUrl;
        Img.onload = resolve;
        Img.onerror = reject;
      });
    }

    function createSmokeObject() {
      smokeObject = document.createElement("div");
      smokeObject.className = "smoke";
    }

    function removeSmoke(smokeElement, delay) {
      setTimeout(function () {
        smokeElement.remove();
      }, delay + smokeLife);
    }

    /**
     * Start Stop Smoke Generator
     */
    function smokeSwitch(status) {
      smokeGeneratorTimer = setInterval(() => {
        smokeGenerator(getRandomInt(1, smokeDensity));
      }, smokeLife);
    }

    function smokeGenerator(smokeDensity) {
      let smokeClone, delay;
      for (let i = 0; i < smokeDensity; i++) {
        smokeClone = smokeObject.cloneNode();
        smokeClone.style.left = getRandomInt(0, 100) + '%';
        delay = getRandomInt(0, smokeLife);
        smokeClone.style.animationDelay = delay + 'ms';
        smokeClone.style.animationDuration = smokeLife + 'ms';
        playground.appendChild(smokeClone);
        smokes.push(smokeClone);
        removeSmoke(smokeClone, delay);
      }
    }

    function smokeEater() {
      console.log('Smokes: ', smokes.length);
      for (var index = 0; index < smokes.length; index++) {
        if (smokes[index].offsetTop < 0) {
          smokes[index].remove();
          smokes.splice(index, 0);
        }
      }
      window.requestAnimationFrame(smokeEater);
    }
    loadImage().then(() => {

      createSmokeObject();
      smokeSwitch();
      smokeEater();
    });


    function getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

  }

  render() {
    return (
      <div className="" >
        <header className="app-wrapper">
          <div className="soc-icons-wrapper">
            <a href="https://discord.gg/uPFmPXvXnQ" target="_blank">
              <SiDiscord className="soc-icon discord"></SiDiscord>
            </a>
            <a href="https://twitter.com/SolanaHavana" target="_blank">
              <SiTwitter className="soc-icon twitter"></SiTwitter>
            </a>
            <a href="https://vm.tiktok.com/ZSeFCwsUD" target="_blank">
              <SiTiktok className="soc-icon tiktok"></SiTiktok>
            </a>
            {/* <a href="" target="_blank">
              <SiInstagram className="soc-icon instagram"></SiInstagram>
            </a> */}
            <div className="listed-on">
              <label>
                As seen on <a target="_blank" href="https://howrare.is/drops">HowRare.is</a>, <a target="_blank" href="https://www.nftdroplist.co.uk/">NFT Droplist</a>
              </label>
            </div>
          </div>
          <div id="playground"></div>
          <div className="container">
            <HomePage></HomePage>
          </div>
        </header>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-WN54517CE3"></script>
      </div>
    );
  }
}

export default App;
